import styled from "styled-components"

import { Radio } from "@material-ui/core"

import { ButtonStyleReset } from "src/ui/Button/MButton"
import { colorsV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export interface IRadioGroup<T> {
  value?: T
  onChange: (value: T) => void
  options?: {
    value: T
    render: () => React.ReactNode | string | number
    renderSecondary?: () => React.ReactNode
    disabled?: boolean
  }[]
  disabled?: boolean
  hideRadio?: boolean
  compare?: (a: T, b: T) => boolean
}

export function RadioGroup<T>({
  options,
  value,
  onChange,
  disabled,
  hideRadio,
  compare = (a, b) => a === b,
}: IRadioGroup<T>) {
  return (
    <InlineSizeContainer>
      <StyledBox $direction="row">
        {options?.map(
          (
            { value: optValue, render, renderSecondary, disabled: optDisabled },
            index
          ) => {
            const _disabled = disabled || optDisabled

            const checked = !!value && compare(value, optValue)

            return (
              <StyledButton
                checked={checked}
                onClick={() => !_disabled && !checked && onChange(optValue)}
                key={`${index}-${optValue}`}
                disabled={_disabled}
              >
                <ContentBox>
                  {!hideRadio && (
                    <RadioBox>
                      <Radio
                        disabled={_disabled}
                        tabIndex={-1}
                        checked={checked}
                      />
                    </RadioBox>
                  )}
                  <MainBox>{render && render()}</MainBox>

                  {!hideRadio && renderSecondary && <SecondaryFiller />}

                  {renderSecondary && (
                    <SecondaryBox>
                      {renderSecondary && renderSecondary()}
                    </SecondaryBox>
                  )}
                </ContentBox>
              </StyledButton>
            )
          }
        )}
      </StyledBox>
    </InlineSizeContainer>
  )
}

const InlineSizeContainer = styled.div`
  container-type: inline-size;
`

const StyledBox = styled.div<{ $direction: "row" | "column" }>`
  display: flex;
  gap: ${spacing.XS};
  flex-direction: column;

  @container (width >= 750px) {
    flex-direction: ${({ $direction }) => {
      return $direction
    }};
  }
`

const StyledButton = styled.button<{ checked?: boolean }>`
  ${ButtonStyleReset}

  flex: 1;

  display: flex;
  align-items: flex-start;
  border: ${({ checked }) =>
    checked
      ? `1px solid ${colorsV2.primary}`
      : `1px solid ${colorsV2.neutral}`};
  color: inherit;
  padding: 0px;
  background: ${colorsV2.neutral};
  border-radius: 8px;

  &:focus {
    background: ${colorsV2.neutral};
    transform: scale(1);
  }

  &:hover {
    border: ${({ checked }) =>
      checked
        ? `1px solid ${colorsV2.primary}`
        : `1px solid ${colorsV2.neutralDark}`};
    background: ${colorsV2.neutral};
  }

  &:active {
    background: ${colorsV2.neutral};
    box-shadow: ${colorsV2.primary} 0px 0px 0px 1px;
  }

  &:disabled {
    background-color: inherit;
    color: inherit;
  }
`

const ContentBox = styled.div`
  display: grid;
  grid-template-areas: "radio main" "filler secondary";
  grid-template-columns: auto 1fr;
  text-align: left;
  width: 100%;
`

const MainBox = styled.div<{ removePadding?: boolean }>`
  grid-area: main;
  ${(props) => !props.removePadding && `padding: ${spacing.M} ${spacing.L}`};
`

const SecondaryBox = styled.div<{ removePadding?: boolean }>`
  grid-area: secondary;
  border-top: 1px solid ${colorsV2.divider};
  background-color: ${colorsV2.neutral};
  ${(props) => !props.removePadding && "padding: 16px"};
  padding: 16px;
`

const SecondaryFiller = styled(SecondaryBox)`
  grid-area: filler;
`

const RadioBox = styled.div`
  grid-area: radio;
  display: grid;
  place-items: center;
`
